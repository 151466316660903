import { render, staticRenderFns } from "./UseCaseList.vue?vue&type=template&id=216e1154&scoped=true"
import script from "./UseCaseList.vue?vue&type=script&lang=js"
export * from "./UseCaseList.vue?vue&type=script&lang=js"
import style0 from "./UseCaseList.vue?vue&type=style&index=0&id=216e1154&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.aspect_rules_js/vue-loader@15.11.1_430222548/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216e1154",
  null
  
)

export default component.exports